import React from 'react'

const grades = [
  { min: 91, grade: 5.0, desc: 'very good' },
  { min: 81, grade: 4.5, desc: 'good plus' },
  { min: 71, grade: 4.0, desc: 'good' },
  { min: 61, grade: 3.5, desc: 'satisfactory plus' },
  { min: 51, grade: 3.0, desc: 'satisfactory' },
  { min: 0, grade: 2.0, desc: 'unsatisfactory' },
]

const Grade: React.FC<any> = ({ points: data }) => {
  if (data && data.fullPoints > 0) {
    const { points, bonusPoints, fullPoints } = data
    let avgPercent = Math.round(((points + bonusPoints) / fullPoints) * 100)
    avgPercent = Math.max(Math.min(avgPercent, 100), 0)
    const grade = grades.find(({ min }) => avgPercent >= min)
    return (
      <span>
        {avgPercent}%, ({points}
        {bonusPoints >= 0 ? '+' : '-'}
        {Math.abs(bonusPoints)}/{fullPoints})
        {grade ? (
          <>
            , grade: {grade.grade}, {grade.desc}
          </>
        ) : null}
      </span>
    )
  } else {
    return <span>no assignments yet</span>
  }
}

export default Grade
