import React from 'react'
import { useContext } from 'react'
import { TocContext } from './tocprovider'
import reactToString from 'react-to-string'

export const Heading: React.FC<any> = ({ level, children }) => {
  const Heading = level ? (`h${level}` as keyof JSX.IntrinsicElements) : null
  const { addItem } = useContext(TocContext)
  if (Heading) {
    return (
      <Heading
        ref={(element) => addItem(level, reactToString(children), element)}
      >
        {children}
      </Heading>
    )
  } else {
    return children
  }
}
