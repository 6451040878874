import React, { useContext, useEffect, useState } from 'react'
import { navigate } from 'gatsby'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import Grade from './grade'
import { AuthContext } from '../authentication/auth-provider'
import { getUserStats } from './query'
import { Heading } from '../layout/tableofcontent/heading'

const problemPoints = (points, mandatory, assignments) => {
  const newPoints = assignments.reduce(
    ({ points: prevPoints, bonusPoints }, { priority, points }) => {
      if (points !== null) {
        return priority === 1 && mandatory
          ? {
              points: (prevPoints === null ? 0 : prevPoints) + points,
              bonusPoints,
            }
          : {
              points: prevPoints,
              bonusPoints: (bonusPoints === null ? 0 : bonusPoints) + points,
            }
      } else {
        return { points: prevPoints, bonusPoints }
      }
    },
    {
      points: null,
      bonusPoints: null,
    }
  )
  return {
    ...newPoints,
    fullPoints:
      mandatory && assignments.some(({ priority }) => priority === 1)
        ? points
        : 0,
  }
}

const ProblemPoints: React.FC<any> = ({
  points: { points, bonusPoints, fullPoints },
}) => {
  return (
    <>
      {points !== null ? points : null}
      {bonusPoints !== null && bonusPoints !== 0
        ? `${bonusPoints < 0 ? '-' : '+'}${Math.abs(bonusPoints)}`
        : null}
      {points === null && bonusPoints === null ? '-' : null}
      {fullPoints !== 0 ? `/${fullPoints}` : null}
    </>
  )
}

const StudentDashboard: React.FC<any> = () => {
  const { user, pendling } = useContext(AuthContext)
  const [usersCourses, setUsersCourses] = useState<any[] | null>(null)

  useEffect(() => {
    let mounted = true
    if (!pendling && !user) {
      navigate('/teaching')
    }
    if (user) {
      getUserStats(user.token).then((stats) => {
        if (mounted) setUsersCourses(stats)
      })
    }
    return () => {
      mounted = false
    }
  }, [pendling, user])

  if (user && usersCourses) {
    return (
      <>
        <p>Hi {user.firstName},</p>
        {usersCourses.length > 0 ? (
          <>
            <p>
              Here, you find a summary of all courses with tutorials you
              attended. For each of them, all exercises problems which have been
              assigned to you are listed togehter with the points you earned for
              each one. These points are summed up and will give rise to your
              final grade for the tutorial.
            </p>
            {usersCourses.map((course) => {
              let totalPoints = { points: 0, bonusPoints: 0, fullPoints: 0 }
              const accumulatePoints = (
                { points, bonusPoints, fullPoints },
                {
                  points: newPoints,
                  bonusPoints: newBonusPoints,
                  fullPoints: newFullPoints,
                }
              ) => {
                return {
                  points: points + newPoints,
                  bonusPoints: bonusPoints + newBonusPoints,
                  fullPoints: fullPoints + newFullPoints,
                }
              }
              return (
                <>
                  <Heading level={2}>{course.name}</Heading>
                  <Heading level={3}>Solved problems</Heading>
                  {course.sheets.map(({ sheet, problems }) => (
                    <ul>
                      <li>
                        Problem sheet {sheet}
                        <ul>
                          {problems.map(
                            ({
                              number,
                              name,
                              points,
                              mandatory,
                              assignments,
                            }) => {
                              const newPoints = problemPoints(
                                points,
                                mandatory,
                                assignments
                              )
                              totalPoints = accumulatePoints(
                                totalPoints,
                                newPoints
                              )
                              return (
                                <li>
                                  <Row>
                                    <Col sm={10}>
                                      {number} {name}
                                    </Col>
                                    <Col sm={2}>
                                      <ProblemPoints points={newPoints} />
                                    </Col>
                                  </Row>
                                </li>
                              )
                            }
                          )}
                        </ul>
                      </li>
                    </ul>
                  ))}
                  <Heading level={3}>Total</Heading>
                  <Grade points={totalPoints} />
                </>
              )
            })}
          </>
        ) : (
          <p>You are not yet registered for any courses.</p>
        )}
      </>
    )
  }
  return <div>Loading, please wait.</div>
}

export default StudentDashboard
