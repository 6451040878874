import React from 'react'

import Layout from '../../components/layout'
import StudentDashboard from '../../components/dashboard/student'

const CoursePage: React.FC<any> = () => {
  return (
    <Layout tocEnabled={true}>
      <StudentDashboard />
    </Layout>
  )
}

export default CoursePage
